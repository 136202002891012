import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTitle from "../components/page-title"
import { OutboundLink } from "gatsby-plugin-google-gtag"

function RequestDemo() {
  return (
    <Layout>
      <SEO title="Request a Call" />
      <PageTitle
        preheader="Book a Call"
        title="Speak To An Expert"
        subtitle="No-commitment call. Pick a timeslot which works for you and we'll see if Nickelled is a fit."
      ></PageTitle>

      <div class="pb-24">
        <iframe
          src="https://hello.omniplexlearning.com/calendar/team/t/12"
          class="w-full"
          style={{ minHeight: "580px" }}
        ></iframe>
      </div>
    </Layout>
  )
}

export default RequestDemo
